import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify"
import Button from '../components/Button';
import Logo from '../assets/Logo.png';
import WhopIcon from '../assets/Whop.svg';

function clearUrl ()
{
  const url = window.location.href.split('?code=')
  if (url[1])
  { window.history.pushState(null, null, url[0]) }
}

const LoginPage = ({ userInfo, setUserInfo, setTasks, setPlanInfo, setIsAuthorised, setIsLoading }) => {
  const [ errorMessage, setErrorMessage ] = useState("")

  const loginProcess = (data) => {
    fetch('https://monitor-api.tweet-catcher.com:9999/dashboard/login', { headers: { 'authorization': data.token } }).then((e) => e.json().then((e) => {
      if (!e.error)
      {
        clearUrl()
        setTasks(e.tasks)
        setPlanInfo({ devPlan: e.devPlan, tasksLimit: e.tasksLimit, expireAt: e.expireAt })
        setIsAuthorised(true)
      }
      else
      {
        setErrorMessage(e.message)
        toast(e.message, { type: "error" })
      }
      setIsLoading(false)
    })).catch((e) => {
      console.error("ERROR 0x1:", e)
      setIsLoading(false)
      setErrorMessage("An error occurred verifying your membership")
      toast("An error occurred verifying your membership", { type: "error" })
    })
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const oauthCode = queryParams.get('code')
    if (oauthCode)
    {
      localStorage.setItem("userInfo", "{}")
      fetch('https://api.tweet-catcher.com:4430/whop-login', {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: ('{"code":"'+oauthCode+'"}')
      }).then((e) => e.json()).then((data) => {
        if (!data.error)
        {
          setUserInfo(data.data)
          localStorage.setItem("userInfo", JSON.stringify(data.data))
          loginProcess(data.data)
        }
        else
        {
          setIsLoading(false)
          setErrorMessage(data.message)
          toast(data.message, { type: "error" })
        }
      }).catch((e) => {
        console.error("ERROR 0x0:", e)
        setIsLoading(false)
        setErrorMessage("An error occurred logging in")
        toast("An error occurred logging in", { type: "error" })
      })
    }
    else
    {
      const info = JSON.parse(localStorage.getItem('userInfo'))
      if (info?.token)
      {
        if (info.expiry > Date.now())
        {
          setUserInfo(info)
          loginProcess(info)
        }
        else
        {
          localStorage.setItem("userInfo", "{}")
          setIsLoading(false)
        }
      }
      else
      { setIsLoading(false) }
    }
 // eslint-disable-next-line
  }, [])

  return (
    <div className="flex items-center justify-center h-screen bg-blue-800">
      <div>
        <div className="flex items-center justify-center">
          <img src={Logo} alt="" className="max-h-72 mb-7" />
        </div>
        <div className="flex items-center justify-center">
          <div className="w-5/6 text-center items-center text-lg capitalize text-red-500">
            {errorMessage}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Button
            className="flex justify-center mt-7 px-[45px] py-3 font-bold w-80"
            onClick={() => {
              setErrorMessage("")
              setIsLoading(true)
              window.location.href = "https://whop.com/oauth?client_id=F1g2Rjw9Kvkv5r6Tzyyd4S_fIyjWxp9NgFMwxn-ZYUo&redirect_uri=https%3A%2F%2Fmonitor.tweet-catcher.com%2F"
            }}
          >
            Login With Whop
            <img src={WhopIcon} alt="" className="max-h-5 ml-3 mt-1" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LoginPage;
