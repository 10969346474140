import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify"
import { Modal } from '@mui/material'
import TaskModal from './utils/TaskModal'
import GridComp from '../components/GridComp'
import Button from '../components/Button'
import SearchIcon from '../assets/Search.svg'
import InfoIcon from '../assets/Info.svg' // (heroicons.com)
import EditIcon from '../assets/Edit.svg'
import PlayIcon from '../assets/Play.svg'
import StopIcon from '../assets/Stop.svg'
import DeleteIcon from '../assets/Delete.svg'

// https://monitor-api.tweet-catcher.com:9999
// http://localhost:9999

const optionsList = {
  posts: "Posts",
  retweets: "Retweets",
  replies: "Replies",
  following: "Following",
//  likes: "Likes", RIP like monitor
  userUpdates: "User Updates",
}

const DashboardPage = ({ tasks, setTasks, planInfo, userInfo }) => {

  const [ searchTerm, setSearchTerm ] = useState("")
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ taskInfo, setTaskInfo ] = useState(null)
  const [ isEdit, setIsEdit ] = useState(false)

  const [ buttonsEnabled, setButtonsEnabled ] = useState(true)

  const [ runningTasks, setRunningTasks ] = useState(tasks.filter((e) => e.running).length)
  useEffect(() => {
    setRunningTasks(tasks.filter((e) => e.running).length)
  }, [tasks])

  const saveNewTask = (task) => {
    const idToast = toast.loading("Saving new task...")
    fetch("https://monitor-api.tweet-catcher.com:9999/dashboard/add-task", {
        method: "POST",
        headers: { 'authorization': userInfo.token, 'content-type': 'application/json' },
        body: JSON.stringify(task)
      }).then((e) => e.json().then((e) => {
        if (!e.error)
        {
          setTasks((old) => ([ ...old, { ...task, id: e.id } ]))
          toast.update(idToast, { render: "Task added!", type: "success", autoClose: 5000, isLoading: false })
        }
        else
        { toast.update(idToast, { render: e.message, type: "error", autoClose: 5000, isLoading: false }) }
      })).catch((e) => {
        console.error("ERROR 0x2:", e)
        toast.update(idToast, { render: "Failed to save the task", type: "error", autoClose: 5000, isLoading: false })
      })
  }

  const startTask = (id) => {
    if (buttonsEnabled)
    {
      setButtonsEnabled(false)
      const idToast = toast.loading("Starting task...")
      fetch("https://monitor-api.tweet-catcher.com:9999/dashboard/start-task", {
          method: "POST",
          headers: { 'authorization': userInfo.token, 'content-type': 'application/json' },
          body: ('{"id":'+id+'}')
        }).then((e) => e.json().then((e) => {
          setButtonsEnabled(true)
          if (!e.error)
          {
            setTasks((old) => {
              const indx = old.findIndex((e) => (e.id === id))
              if (indx !== -1)
              {
                const copy = [ ...old ]
                copy[indx] = { ...copy[indx], running: true }
                return copy
              }
              else
              { return old }
            })
            toast.update(idToast, { render: "Task started!", type: "success", autoClose: 5000, isLoading: false })
          }
          else
          { toast.update(idToast, { render: e.message, type: "error", autoClose: 5000, isLoading: false }) }
        })).catch((e) => {
          setButtonsEnabled(true)
          console.error("ERROR 0x3:", e)
          toast.update(idToast, { render: "Failed to start the task", type: "error", autoClose: 5000, isLoading: false })
        })
    }
  }

  const editTask = (editedTask) => {
    if (buttonsEnabled)
    {
      setButtonsEnabled(false)
      const idToast = toast.loading("Editing task...")
      fetch("https://monitor-api.tweet-catcher.com:9999/dashboard/edit-task", {
          method: "POST",
          headers: { 'authorization': userInfo.token, 'content-type': 'application/json' },
          body: JSON.stringify(editedTask)
        }).then((e) => e.json().then((e) => {
          setButtonsEnabled(true)
          if (!e.error)
          {
            setTasks((old) => {
              const indx = old.findIndex((e) => (e.id === editedTask.id))
              if (indx !== -1)
              {
                const copy = [ ...old ]
                copy[indx] = { ...editedTask, running: false }
                return copy
              }
              else
              { return old }
            })
            toast.update(idToast, { render: "Task updated!", type: "success", autoClose: 5000, isLoading: false })
          }
          else
          { toast.update(idToast, { render: e.message, type: "error", autoClose: 5000, isLoading: false }) }
        })).catch((e) => {
          setButtonsEnabled(true)
          console.error("ERROR 0x6:", e)
          toast.update(idToast, { render: "Failed to edit the task", type: "error", autoClose: 5000, isLoading: false })
        })
    }
  }

  const stopTask = (id) => {
    if (buttonsEnabled)
    {
      setButtonsEnabled(false)
      const idToast = toast.loading("Stopping task...")
      fetch("https://monitor-api.tweet-catcher.com:9999/dashboard/stop-task", {
          method: "POST",
          headers: { 'authorization': userInfo.token, 'content-type': 'application/json' },
          body: ('{"id":'+id+'}')
        }).then((e) => e.json().then((e) => {
          setButtonsEnabled(true)
          if (!e.error)
          {
            setTasks((old) => {
              const indx = old.findIndex((e) => (e.id === id))
              if (indx !== -1)
              {
                const copy = [ ...old ]
                copy[indx] = { ...copy[indx], running: false }
                return copy
              }
              else
              { return old }
            })
            toast.update(idToast, { render: "Task stopped!", type: "success", autoClose: 5000, isLoading: false })
          }
          else
          { toast.update(idToast, { render: e.message, type: "error", autoClose: 5000, isLoading: false }) }
        })).catch((e) => {
          setButtonsEnabled(true)
          console.error("ERROR 0x4:", e)
          toast.update(idToast, { render: "Failed to stop the task", type: "error", autoClose: 5000, isLoading: false })
        })
    }
  }

  const deleteTask = (id) => {
    if (buttonsEnabled)
    {
      setButtonsEnabled(false)
      const idToast = toast.loading("Deleting task...")
      fetch("https://monitor-api.tweet-catcher.com:9999/dashboard/delete-task", {
          method: "POST",
          headers: { 'authorization': userInfo.token, 'content-type': 'application/json' },
          body: ('{"id":'+id+'}')
        }).then((e) => e.json().then((e) => {
          setButtonsEnabled(true)
          if (!e.error)
          {
            setTasks((old) => old.filter((e) => (e.id !== id)))
            toast.update(idToast, { render: "Task deleted!", type: "success", autoClose: 5000, isLoading: false })
          }
          else
          { toast.update(idToast, { render: e.message, type: "error", autoClose: 5000, isLoading: false }) }
        })).catch((e) => {
          setButtonsEnabled(true)
          console.error("ERROR 0x5:", e)
          toast.update(idToast, { render: "Failed to delete the task", type: "error", autoClose: 5000, isLoading: false })
        })
    }
  }

  return (<>
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <div className="w-full h-full outline-none">
        <TaskModal
          taskInfo={taskInfo}
          isEdit={isEdit}
          devPlan={planInfo.devPlan}
          onSubmitSave={saveNewTask}
          onSubmitUpdate={editTask}
          onClose={() => {
            setIsEdit(false)
            setModalOpen(false)
            setTaskInfo(null)
          }}
        ></TaskModal>
      </div>
    </Modal>
    <div className="flex items-center justify-center h-screen bg-blue-800">
      <div className="grid gap-4 grid-cols-4 grid-rows-4 md:h-3/4 h-4/5 md:w-3/5 w-11/12">
        <GridComp className="col-span-3 flex flex-wrap content-center items-center justify-around">
          <div className="w-full flex justify-around md:flex-nowrap flex-wrap">
            <div className="flex items-center justify-center">
              <input
                type="text"
                className="bg-blue-800 rounded-lg text-white border border-[rgba(255,255,255,0.1)] text-lg outline-none py-1 px-4 ml-3 md:w-56 w-32"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              ></input>
              <img src={SearchIcon} alt="" className="ml-3 max-h-7"/>
            </div>
            <Button
              className="px-4 py-1"
              onClick={() => {
                setModalOpen(true)
              }}
            >Add Task</Button>
          </div>
          <div className="w-full flex justify-center mt-3">
            <div className="w-2/3">
              <div className="flex justify-between mb-0.5">
                <span className="font-medium text-base text-white">Running tasks <span
                  className="font-medium text-sm text-blue-500 cursor-pointer"
                  onClick={() => window.open("https://tweet-catcher.gitbook.io/tweet-catcher/tweet-catcher-guide/tweet-catcher-monitor/extra-tasks", "_blank")}
                >(Need more?)</span></span>
                <span className="text-base font-medium text-white">{runningTasks+" / "+planInfo.tasksLimit}</span>
              </div>
              <div className="w-full bg-gray rounded-full h-2">
                <div
                  className="bg-blue-500 h-2 rounded-full"
                  style={{ width: (Math.floor((runningTasks/planInfo.tasksLimit)*100)+"%") }}></div>
              </div>
            </div>
          </div>
        </GridComp>
        <GridComp className="flex flex-wrap content-center items-center justify-around">
          <div className="w-full flex justify-around md:flex-nowrap flex-wrap">
            <Button red={true} className="px-4 py-1" onClick={(e) => {
              localStorage.setItem("userInfo", "{}")
              window.location.reload()
            }}>Logout</Button>
          </div>
          {(() => {
            if (planInfo.expireAt)
            {
              const daysLeft = Math.ceil((planInfo.expireAt * 1000 - Date.now()) / (1000 * 60 * 60 * 24))
              let textColor = 'text-green'
              if (daysLeft <= 5)
              { textColor = 'text-red-500' }
              else if (daysLeft <= 10)
              { textColor = 'text-yellow-500' }
              
              return (
                <div className="w-3/4 flex justify-center mt-3">
                  <div className="flex flex-wrap items-center justify-center">
                    <span className={`font-medium text-base ${textColor} mr-2`}>
                      Days left: {daysLeft}
                    </span>
                    <span
                      className="font-medium text-sm text-blue-500 cursor-pointer"
                      onClick={() => window.open("https://whop.com/hub/memberships/", "_blank")}
                    >(Top up)</span>
                  </div>
                </div>
              )
            }
            else
            { return null }
          })()}
        </GridComp>
        <GridComp className="col-span-4 row-span-3 py-7 flex-none overflow-y-scroll">
          {tasks.length?
            (<div className="w-full flex justify-center">
              <div className="w-11/12">
                {tasks.filter((e) => (e.user.toLowerCase().includes(searchTerm.toLowerCase()))).map((e, i) => (
                  <div key={i} className="flex md:flex-nowrap flex-wrap w-full md:h-16 h-32 bg-blue-800 rounded-lg text-white border border-[rgba(255,255,255,0.1)] outline-none py-1 px-4 mb-1">
                    <div className="md:w-1/3 w-1/2 md:h-full h-1/2 flex items-center text-white text-lg capitalize pl-3">
                      {e.user}
                    </div>
                    <div className="md:w-1/3 w-1/2 md:h-full h-1/2 flex items-center justify-center text-lg capitalize text-blue-500">
                      {e.options?.map((e) => optionsList[e]).filter((e) => e).join(", ")}
                    </div>
                    <div className={"md:w-1/6 w-1/2 md:h-full h-1/2 flex items-center md:justify-center md:pl-0 pl-3 text-lg capitalize "+(e.running? "text-green": "text-red-500")}>
                      {e.running? "Running...": "Stopped"}
                    </div>
                    <div className="md:w-1/6 w-5/12 md:h-full h-1/2 flex items-center justify-end text-lg capitalize mr-2 ml-3">
                      {e.running?
                        (<>
                          <img src={StopIcon} alt="" onClick={() => stopTask(e.id)} className="max-h-8 cursor-pointer" />
                          <img src={InfoIcon} alt="" onClick={() => {
                            setIsEdit(false)
                            setTaskInfo(e)
                            setModalOpen(true)
                          }} className="max-h-8 cursor-pointer" />
                        </>):
                        (<>
                          <img src={PlayIcon} alt="" onClick={() => startTask(e.id)} className="max-h-8 cursor-pointer" />
                          <img src={DeleteIcon} alt="" onClick={() => deleteTask(e.id)} className="max-h-8 cursor-pointer" />
                          <img src={EditIcon} alt="" onClick={() => {
                            setIsEdit(true)
                            setTaskInfo(e)
                            setModalOpen(true)
                          }} className="max-h-8 cursor-pointer" />
                        </>)
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>):
            (<div className="w-full h-full flex items-center justify-around text-white text-2xl">
              No saved task
            </div>)
          }
        </GridComp>
      </div>
    </div>
  </>)
}

export default DashboardPage