import React from 'react';
import { Checkbox, styled } from '@mui/material';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: '#000415', // Color when unchecked
  padding: "0 !important",
  '&.Mui-checked': {
    color: '#000415', // Color when checked
  },
  '& .MuiTouchRipple-root': {
    display: 'none', // Remove ripple effect
  },
  '&:hover': {
    backgroundColor: 'transparent', // Remove hover background
  },
  '& .MuiSvgIcon-root': {
    fontSize: 22, // Size of the icon
  },
}));

//<svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//  <rect width="24" height="24" rx="4" fill="#000415"/>
//  <path d="M20 6L9 17L4 12" stroke="#50d0b9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//</svg>

const unCheckedIcon = (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22" height="22" rx="3" fill="#000415" stroke="rgba(255,255,255,0.1)" strokeWidth="1"/>
  </svg>
)

const checkedIcon = (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22" height="22" rx="3" fill="#000415" stroke="rgba(255,255,255,0.1)" strokeWidth="1"/>
    <polyline id="primary" points="5 12 10 17 19 8" style={{fill: "none", stroke: "#50d0b9", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2}}></polyline>
  </svg>
)
// <span style={{ backgroundColor: '#000415', width: 22, height: 22, display: 'block', borderRadius: 4, borderWidth: 1, borderColor: 'rgba(255,255,255,0.1)' }}></span>

const CheckboxComp = ({ checked, onChange, className = "", text, ...rest }) => {
  return (
    <div className={`${className} flex justify-between text-base`} >
      {text? (<div className="mr-3">{text}</div>): null}
      <CustomCheckbox
        checked={checked}
        onChange={onChange}
        icon={unCheckedIcon}
        checkedIcon={checkedIcon}
        {...rest}
      />
    </div>
  );
};

export default CheckboxComp