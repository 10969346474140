import React from 'react';
import { CircularProgress } from '@mui/material';

const LoadingPage = ({ isLoading }) => {

  return (
    isLoading?
      (<div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center bg-blue-800 opacity-80">
        <CircularProgress sx={{ color:"#515be6" }} size={70} thickness={4} />
      </div>):
      ""
  )
}

export default LoadingPage;
