import React, { useState } from 'react'
import { useFormik } from "formik"
import { Select, MenuItem } from "@mui/material"
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import UpDownIcon from '../../assets/UpDown.svg'
import HelpIcon from '../../assets/Help.svg'

const IconComponent = () => {
  return (
    <div className="absolute right-1 w-7 top-0 bottom-0 my-auto h-7 cursor-pointer">
      <img alt="expand" src={UpDownIcon}></img>
    </div>
  )
}

// dont change names or it'll mess the sort of the options select, required to nicely display multiple webhook option
const optionsList = [
  [ "posts", "Posts" ],
  [ "retweets", "\u00B7 Retweets", true ], // require posts to be visible / selected
  [ "replies", "\u00B7 Replies", true ], // require posts to be visible / selected
  [ "following", "Following" ],
//  [ "likes", "Likes" ], RIP like monitor
  [ "userUpdates", "User Updates" ],
]

const notificationList = [
  [ "discord", "Discord" ],
  [ "telegram", "Telegram" ],
  [ "webhook", "Webhook" ],
  [ "websocket", "WebSocket" ],
]

const pingsList = [
  [ "none", "No Ping" ],
  [ "everyone", "Everyone (@everyone)" ],
  [ "here", "Here (@here)" ],
  [ "role", "Role" ],
]

const parseKeywords = (str, isRegex) => {
  if (isRegex)
  { return ({ n: [], p: [], regex: str, isRegex: true }) }
  else
  {
    const keyWrds = str.split(/,\s*/).filter((e) => e)
    const result = { n: [], p: [] }
    keyWrds.forEach((k) => {
      if (k.startsWith("-"))
      { result.n.push(k.substring(1)) }
      else // no check for + so compatible for users using no + for positive
      { result.p.push(k.startsWith("+")? k.substring(1): k) }
    })
    return result
  }
}

const stringifyKeywords = (keywords) => {
  //return keywords.join(", ")
  if (keywords.isRegex)
  { return keywords.regex }
  else
  {
    const allKewords = [ ...keywords.p.map((e) => ("+"+e)), ...keywords.n.map((e) => ("-"+e)) ].join(", ")
    return allKewords
  }
}

const validate = (values, pingKeywords) => {
  const errors = {}
  
  if (!values.user)
  { errors.user = "Required" }
  else if (!values.user.match(/^[a-zA-Z0-9-_]+$/))
  { errors.user = "Invalid" }

  if (values.options.length === 0)
  { errors.options = "Required" }

  if (values.notification === "discord")
  {
    if (!values.webhook)
    { errors.webhook = "Required" }
    //else if (!values.webhook.match(/^$/))
    //{ errors.webhook = "Invalid" }

    if (values.ping === "role")
    {
      if (!values.roleId)
      { errors.roleId = "Required" }
      else if (!values.roleId.match(/^\d+$/))
      { errors.roleId = "Invalid" }
    }
  }
  else if (values.notification === "telegram")
  {
    if (!values.chatId)
    { errors.chatId = "Required" }
  }
  else if (values.notification === "webhook")
  {
    if (!values.webhook)
    { errors.webhook = "Required" }
  }

  if (values.useRegex && pingKeywords)
  {
    try
    { new RegExp(pingKeywords) }
    catch (e)
    { errors.pingKeywords = "Invalid" }
  }

  return errors
}

const TaskModal = ({ taskInfo, isEdit, devPlan, onSubmitSave, onSubmitUpdate, onClose }) => {
  const [ pingKeywords, setPingKeywords ] = useState((taskInfo?.pingKeywords)? stringifyKeywords(taskInfo.pingKeywords): "")

  const { values, handleSubmit, setFieldValue, handleChange, errors, submitCount } = useFormik({
    onSubmit: (values, { resetForm }) => {
      if (!taskInfo)
      {
        values.pingKeywords = parseKeywords(pingKeywords, values.useRegex)
        onSubmitSave(values)
      }
      else
      {
        if (isEdit)
        {
          values.pingKeywords = parseKeywords(pingKeywords, values.useRegex)
          onSubmitUpdate(values)
        }
      }
      //console.log("task", values)

      resetForm()
      onClose()
    },
    initialValues: {
      user: "",
      options: [ "posts" ],

      notification: "discord",
      webhook: "",
      chatId: "",

      pingKeywords: {},
      useRegex: false,
      ping: "role",
      roleId: "",
      running: false,
      ...taskInfo
    },
    validate: (v) => { return validate(v, pingKeywords) }
  })
  
  return (
    <div
      className="w-full h-full overflow-auto flex items-center justify-center"
      onMouseDown={onClose}
    >
      <form
        onSubmit={handleSubmit}
        className="bg-blue-800 rounded-3xl border border-[rgba(255,255,255,0.1)] max-w-xl w-full md:px-16 px-5 py-7 text-white md:text-xl text-lg"
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center mb-1">
          <div className="mx-auto text-center text-2xl mb-5 font-semibold pl-4">
            {taskInfo? (isEdit? "Update Task": "Task Info"): "Create A Task"}
          </div>
          <a href="https://tweet-catcher.gitbook.io/tweet-catcher/tweet-catcher-guide/tweet-catcher-monitor/tasks-creation" target="_blank" rel="noopener noreferrer" className="w-8">
            <img src={HelpIcon} alt="Help Icon" className="max-h-8 cursor-pointer" />
          </a>
        </div>
        <div className="flex justify-around mb-1">
          <div className="w-1/2 mr-2">
            <div className="flex justify-between">
              <div className="flex justify-between text-lg">
                <div>User Handle</div>
              </div>
              <div className="text-red-500">
                {(submitCount > 0 && errors.user)? errors.user : ""}
              </div>
            </div>
            <input
              type="text"
              name="user"
              spellCheck={false}
              className="bg-blue-900 rounded-lg text-white border border-[rgba(255,255,255,0.1)] w-full outline-none py-1.5 px-4"
              value={values.user || ""}
              onChange={handleChange}
              readOnly={!!(taskInfo && !isEdit)}
            ></input>
          </div>
          <div className="w-1/2 ml-1">
            <div className="flex justify-between">
              <div className="flex justify-between text-lg">
                <div>Options</div>
              </div>
              <div className="text-red-500">
                {(submitCount > 0 && errors.options)? errors.options : ""}
              </div>
            </div>
            <Select
              value={values.options}
              IconComponent={IconComponent}
              className="w-full outline-none text-white"
              sx={{
                '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
                '.MuiOutlinedInput-input': {
                  padding: "0.375rem 1rem",
                  color: "white",
                  fontSize: "1.1rem",
                  lineHeight: "1.75rem"
                }
              }}
              slotProps={{
                root: { className: 'bg-blue-900 rounded-lg text-white border border-[rgba(255,255,255,0.1)] w-full outline-none' },
              }}
              onChange={(e) => {
                let selectedOptions = e.target.value

                selectedOptions.sort((a, b) => a.length - b.length) // sort to nice display multiple webhookls option
                setFieldValue("options", selectedOptions)

                if (selectedOptions.filter((e) => !["retweets", "replies"].includes(e)).length <= 1)
                { setFieldValue("differentWebhooks", false) }
              }}
              readOnly={!!(taskInfo && !isEdit)}
              multiple
            >
              {optionsList.map((e, i) => (
                <MenuItem key={i} value={e[0]} sx={{
                  '&.Mui-selected': { backgroundColor: "#515be6 !important", color: "white !important" },
                  '&.Mui-selected:hover': { backgroundColor: "#515be6 !important" }
                }}>
                  {e[1]}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="flex justify-around mb-1">
          <div className={(values.notification === "discord")? "w-2/5 mr-3": "w-4/5 mr-3"}>
            <div className="flex justify-between text-lg">
              <div>Notification</div>
            </div>
            <Select
              value={values.notification}
              IconComponent={IconComponent}
              className="w-full outline-none text-white"
              sx={{
                '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
                '.MuiOutlinedInput-input': {
                  padding: "0.375rem 1rem",
                  color: "white",
                  fontSize: "1.1rem",
                  lineHeight: "1.75rem"
                }
              }}
              slotProps={{
                root: { className: 'bg-blue-900 rounded-lg text-white border border-[rgba(255,255,255,0.1)] w-full outline-none' },
              }}
              onChange={(e) => {
                setFieldValue("notification", e.target.value)
              }}
              readOnly={!!(taskInfo && !isEdit)}
            >
              {notificationList.filter((e) => (e[0] !== "websocket" || devPlan?.isDevPlan)).map((e, i) => (
                <MenuItem key={i} value={e[0]} sx={{
                  '&.Mui-selected': { backgroundColor: "#515be6 !important", color: "white !important" },
                  '&.Mui-selected:hover': { backgroundColor: "#515be6 !important" }
                }}>
                  {e[1]}
                </MenuItem>
              ))}
            </Select>
          </div>
          {(values.notification === "discord")? (
            <div className="w-3/5">
              <div className="flex justify-between text-lg">
                <div>Ping Option</div>
              </div>
              <Select
                value={values.ping}
                IconComponent={IconComponent}
                className="w-full outline-none text-white"
                sx={{
                  '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
                  '.MuiOutlinedInput-input': {
                    padding: "0.375rem 1rem",
                    color: "white",
                    fontSize: "1.1rem",
                    lineHeight: "1.75rem"
                  }
                }}
                slotProps={{
                  root: { className: 'bg-blue-900 rounded-lg text-white border border-[rgba(255,255,255,0.1)] w-full outline-none' },
                }}
                onChange={(e) => {
                  setFieldValue("ping", e.target.value)
                }}
                readOnly={!!(taskInfo && !isEdit)}
              >
                {pingsList.map((e, i) => (
                  <MenuItem key={i} value={e[0]} sx={{
                    '&.Mui-selected': { backgroundColor: "#515be6 !important", color: "white !important" },
                    '&.Mui-selected:hover': { backgroundColor: "#515be6 !important" }
                  }}>
                    {e[1]}
                  </MenuItem>
                ))}
              </Select>
            </div>
          ): null}
        </div>
        {(() => {
          switch (values.notification) {
            case "discord":
              return (<div className="flex justify-around mb-1">
                <div className="w-full">
                  <div className="flex justify-between w-full">
                    <div className="flex justify-between text-lg">
                      <div>{"Webhoook "+(values.differentWebhooks? optionsList.find((b) => (b[0] === values.options[0]))[1]: "")}</div>
                    </div>
                    <div className="text-red-500">
                      {(submitCount > 0 && errors.webhook)? errors.webhook : ""}
                    </div>
                    {(values.options.filter((e) => !["retweets", "replies"].includes(e)).length > 1)? (<Checkbox
                      text="Notify on same channel"
                      checked={!values.differentWebhooks}
                      onChange={(e) => (!(taskInfo && !isEdit)? setFieldValue("differentWebhooks", !values.differentWebhooks): {})}
                    ></Checkbox>): null}
                  </div>
                  <input
                    type="text"
                    name="webhook"
                    spellCheck={false}
                    className="bg-blue-900 rounded-lg text-white border border-[rgba(255,255,255,0.1)] w-full outline-none py-1.5 px-4"
                    value={values.webhook || ""}
                    onChange={handleChange}
                    readOnly={!!(taskInfo && !isEdit)}
                  ></input>
                </div>
              </div>)
            case "telegram":
              return (<div className="flex justify-around mb-1">
                <div className="w-full">
                  <div className="flex justify-between">
                    <div className="flex justify-between text-lg">
                      <div>{"Chat ID  "+(values.differentWebhooks? optionsList.find((b) => (b[0] === values.options[0]))[1]: "")}</div>
                    </div>
                    <div className="text-red-500">
                      {(submitCount > 0 && errors.chatId)? errors.chatId : ""}
                    </div>
                    {(values.options.filter((e) => !["retweets", "replies"].includes(e)).length > 1)? (<Checkbox
                      text="Notify on same channel"
                      checked={!values.differentWebhooks}
                      onChange={(e) => (!(taskInfo && !isEdit)? setFieldValue("differentWebhooks", !values.differentWebhooks): {})}
                    ></Checkbox>): null}
                  </div>
                  <input
                    type="text"
                    name="chatId"
                    spellCheck={false}
                    className="bg-blue-900 rounded-lg text-white border border-[rgba(255,255,255,0.1)] w-full outline-none py-1.5 px-4"
                    value={values.chatId || ""}
                    onChange={handleChange}
                    readOnly={!!(taskInfo && !isEdit)}
                  ></input>
                </div>
              </div>)
            case "webhook":
              return (<div className="flex justify-around mb-1">
                <div className="w-full">
                  <div className="flex justify-between w-full">
                    <div className="flex justify-between text-lg">
                      <div>Webhook</div>
                    </div>
                    <div className="text-red-500">
                      {(submitCount > 0 && errors.webhook)? errors.webhook : ""}
                    </div>
                  </div>
                  <input
                    type="text"
                    name="webhook"
                    spellCheck={false}
                    className="bg-blue-900 rounded-lg text-white border border-[rgba(255,255,255,0.1)] w-full outline-none py-1.5 px-4"
                    value={values.webhook || ""}
                    onChange={handleChange}
                    readOnly={!!(taskInfo && !isEdit)}
                  ></input>
                </div>
              </div>)
            case "websocket":
              return (<div className="flex justify-around mb-1">
                <div className="w-full">
                  <div className="flex justify-between">
                    <div className="flex justify-between text-lg">
                      <div>Login Token</div>
                    </div>
                  </div>
                  <textarea
                    type="text"
                    name="wsToken"
                    spellCheck={false}
                    className="bg-blue-900 rounded-lg text-neutral-400 resize-none min-h-36 border border-[rgba(255,255,255,0.1)] w-full outline-none py-1.5 px-4"
                    value={devPlan?.token || ""}
                    readOnly={true}
                  ></textarea>
                </div>
              </div>)
            default:
              return null
          }
        })()}
        {values.differentWebhooks && ["discord", "telegram"].includes(values.notification)?
          (values.options.filter((e) => !["retweets", "replies"].includes(e)).slice(1).map((e) => {
            const name = (((values.notification === "telegram")? "chatId-": "webhook-") + e)
            const description = (((values.notification === "telegram")? "Chat ID ": "Webhook ") + optionsList.find((b) => (b[0] === e))[1])

            return (<div key={e} className="flex justify-around mb-1">
              <div className="w-full">
                <div className="flex justify-between w-full">
                  <div className="flex justify-between text-lg">
                    <div>{description}</div>
                  </div>
                  <div className="text-red-500">
                    {(submitCount > 0 && errors[name])? errors[name] : ""}
                  </div>
                </div>
                <input
                  type="text"
                  name={name}
                  spellCheck={false}
                  className="bg-blue-900 rounded-lg text-white border border-[rgba(255,255,255,0.1)] w-full outline-none py-1.5 px-4"
                  value={values[name] || ""}
                  onChange={handleChange}
                  readOnly={!!(taskInfo && !isEdit)}
                ></input>
              </div>
            </div>)
          })
        ): null}
        {(values.notification === "discord")? (<>
          <div className="flex justify-around mb-1">
            {(values.ping === "role")? (
              <div className="w-full">
                <div className="flex justify-between">
                  <div className="flex justify-between text-lg">
                    <div>Ping Role ID</div>
                  </div>
                  <div className="text-red-500">
                    {(submitCount > 0 && errors.roleId)? errors.roleId : ""}
                  </div>
                </div>
                <input
                  type="text"
                  name="roleId"
                  spellCheck={false}
                  className="bg-blue-900 rounded-lg text-white border border-[rgba(255,255,255,0.1)] w-full outline-none py-1.5 px-4"
                  value={values.roleId || ""}
                  onChange={handleChange}
                  readOnly={!!(taskInfo && !isEdit)}
                ></input>
              </div>
            ): null}
          </div>
          {(values.ping !== "none" && (values.options.includes("posts") || values.options.includes("retweets") || values.options.includes("replies")))? (<div className="flex justify-around mb-1">
            <div className="w-full">
              <div className="flex justify-between">
                <div className="flex justify-between text-lg w-full">
                  <div>{values.useRegex? "Ping Regex": "Ping Keywords"}</div>
                  <div className="text-red-500">
                    {(submitCount > 0 && errors.pingKeywords)? errors.pingKeywords : ""}
                  </div>
                  <Checkbox
                    text="Use Regex"
                    checked={values.useRegex}
                    onChange={(e) => (!(taskInfo && !isEdit)? setFieldValue("useRegex", !values.useRegex): {})}
                  ></Checkbox>
                </div>
              </div>
              <input
                type="text"
                name="keywords"
                spellCheck={false}
                className="bg-blue-900 rounded-lg text-white border border-[rgba(255,255,255,0.1)] w-full outline-none py-1.5 px-4"
                value={pingKeywords}
                placeholder="Leave empty to ping every Tweet"
                onChange={(e) => setPingKeywords(e.target.value)}
                readOnly={!!(taskInfo && !isEdit)}
              ></input>
            </div>
          </div>): null}
        </>): null}
        <div className="flex justify-center mt-7">
          <Button type="submit" className="px-12 py-1" onClick={handleSubmit}>{taskInfo? (isEdit? "Update": "Close"): "Save"}</Button>
        </div>
      </form>
    </div>
  )
}

export default TaskModal
