import React from "react";

// px-5 py-2

const Button = ({ className = "", red, ...rest }) => {
  return (
    <div
      type="button"
      {...rest}
      className={`${className} text-center box-border no-underline not-italic font-normal text-[17px] leading-7 text-white border inline-block m-0 rounded-lg border-solid border-[rgba(255,255,255,0.1)] ${red? "bg-red-500 hover:bg-red-501": "bg-blue-500 hover:bg-blue-501"} cursor-pointer`}
    ></div>
  );
};

export default Button;
