import React from "react";

const GridComp = ({ className = "", ...rest }) => {
  return (
    <div
      {...rest}
      className={`${className} bg-blue-700 leading-normal text-base font-normal box-border border min-h-full rounded-[20px] border-solid border-[rgba(255,255,255,0.1)]`}
    ></div>
  )
}

export default GridComp
