
import React, { useState } from "react";
import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"
import Loading from "./components/Loading"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function App() {
  const [ isLoading, setIsLoading ] = useState(true)
  const [ isAuthorised, setIsAuthorised ] = useState(false)
  const [ userInfo, setUserInfo ] = useState({})

  const [ tasks, setTasks ] = useState([])
  const [ planInfo, setPlanInfo ] = useState({})

  return (
    <>
      <Loading isLoading={isLoading}></Loading>
      {
        isAuthorised?
          <Dashboard tasks={tasks} setTasks={setTasks} planInfo={planInfo} userInfo={userInfo}></Dashboard>:
          <Login userInfo={userInfo} setUserInfo={setUserInfo} setTasks={setTasks} setPlanInfo={setPlanInfo} setIsAuthorised={setIsAuthorised} setIsLoading={setIsLoading}></Login>
      }
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: "#000415",
          color: "white",
          borderRadius: "0.5rem",
          borderWidth: "1px",
          borderColor: "rgba(255,255,255,0.1)"
        }}
      />
    </>
  )
}

export default App;
